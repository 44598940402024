import { ApolloCustomerErrorAlert } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorAlert'
import {
  ForgotPasswordDocument,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
} from '@graphcommerce/magento-customer/components/ForgotPasswordForm/ForgotPassword.gql'
import { Form, FormActions } from '@graphcommerce/next-ui'
import { emailPattern, useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert } from '@mui/material'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { StandardButton } from '../../Button/ButtonStyles'
import { StandardTextField } from '../../TextInput/TextFieldStyles'

type ForgotPasswordFormProps = {
  email?: string | null | undefined
  setMode?: Dispatch<
    SetStateAction<'email' | 'signin' | 'signup' | 'signedin' | 'session-expired' | 'forgot'>
  >
}

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const { email, setMode } = props
  const form = useFormGqlMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
    {
      defaultValues: {
        email: email || '',
      },
    },
  )
  const { register, handleSubmit, required, data, formState, watch, error } = form
  const submitHandler = handleSubmit(() => {})

  const handleRetryLogin = () => {
    if (formState?.isSubmitSuccessful && setMode) {
      setMode('signin')
    }
  }

  if (formState.isSubmitSuccessful && data) {
    return (
      <Alert
        severity='success'
        variant='standard'
        sx={(theme) => ({
          marginTop: theme.spacings.md,
          marginBottom: theme.spacings.sm,
        })}
      >
        <Trans id='We’ve send a password reset link to your email address!' />
        <button type='button' onClick={handleRetryLogin} className='mt-2 flex'>
          <span className='block text-left text-material-ui-blue hover:text-material-ui-dark-blue hover:underline'>
            {i18n._('Click here to log in')}
          </span>
        </button>
      </Alert>
    )
  }

  return (
    <Form onSubmit={submitHandler} noValidate>
      <StandardTextField
        label={<Trans id='Email address' />}
        variant='outlined'
        type='email'
        autoComplete='email'
        color='primary'
        size='small'
        required={required.email}
        error={formState.isSubmitted && !!formState.errors.email}
        disabled={formState.isSubmitting}
        {...register('email', {
          required: required.email,
          pattern: { value: emailPattern, message: i18n._(/* i18n */ 'Invalid email address') },
        })}
        className='w-full'
        value={watch('email')}
      />

      <ApolloCustomerErrorAlert error={error} />

      <FormActions>
        <StandardButton
          type='submit'
          className='w-[260px] max-w-[260px] bg-material-ui-blue uppercase'
          variant='contained'
          loading={formState.isSubmitting}
        >
          {i18n._('Send password reset email')}
        </StandardButton>
      </FormActions>
    </Form>
  )
}
